<fieldflow-primary-layout (logOut)="logOut()" (searchClicked)="searchClicked()" [name]="name" [options]="options"
                          [role]="role"
                          [sidebarOptions]="sidebarOptions">

  <div *useFeatureFlag="'enable-dynamic-pricing'; defaultValue: false" class="beta-badge">
    <div>
      <mat-icon fontSet="material-symbols-outlined">diamond</mat-icon>
      Beta
    </div>
    Dynamic pricing is enabled on your system.
  </div>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>

</fieldflow-primary-layout>

<ibm-placeholder></ibm-placeholder>

<app-global-callout></app-global-callout>
