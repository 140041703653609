import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {SettingsDropdownOptionModel} from "./models/settings-dropdown-option.model";

@Component({
  selector: 'fieldflow-navbar',
  templateUrl: './field-flow-navbar.component.html',
  styleUrls: ['./field-flow-navbar.component.css']
})
export class FieldFlowNavbarComponent implements OnInit {

  @Output() searchClicked = new EventEmitter();
  @Input() name: string;
  @Input() role: string;
  @Output() logOut = new EventEmitter();
  @Input() options: SettingsDropdownOptionModel[] = [];

  userDropdownAnimationState: 'fadeOut' | 'fadeIn' = 'fadeOut';

  settingsDropdownAnimationState: 'fadeOut' | 'fadeIn' = 'fadeOut';

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (this.userDropdownAnimationState === 'fadeIn' && !this.elementRef.nativeElement.contains(targetElement)) {
      this.userDropdownAnimationState = 'fadeOut';
    }

    if (this.settingsDropdownAnimationState === 'fadeIn' && !this.elementRef.nativeElement.contains(targetElement)) {
      this.settingsDropdownAnimationState = 'fadeOut';
    }
  }

  handleDropdownClick(dropdown: 'user' | 'settings') {
    // If we ever need to add more dropdowns,
    // this component should be optimized to prevent a bunch of variables tracking animation state.
    // For now, two variables are fine.
    switch (dropdown) {
      case 'user':
        this.settingsDropdownAnimationState = 'fadeOut';
        this.userDropdownAnimationState = this.userDropdownAnimationState === 'fadeOut' ? 'fadeIn' : 'fadeOut';
        break;
      case 'settings':
        this.userDropdownAnimationState = 'fadeOut';
        this.settingsDropdownAnimationState = this.settingsDropdownAnimationState === 'fadeOut' ? 'fadeIn' : 'fadeOut';
        break;
    }
  }

}
